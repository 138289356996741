import React, { useState } from 'react';

import * as offerCalc from '../../api/offerCalc';

import { Button } from '@mui/material';

import ProviderLogo from './ProviderLogo';
import OrderButton from './OrderButton';
import OfferDetailsPopup from './OfferDetailsPopup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './FeaturedOffer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OfferRowElectric from './OfferRowElectric';

function FeaturedOffer({ offer, priceColumn, usage, usageSetting }) {
  const [showOfferDetails, setShowOfferDetails] = useState(null);

  return offer.SingleMonthPriceKWh ? (
    <li className="featured-offer">
      <div className="content">
        {offer.label && (
          <h4 style={{ backgroundColor: offer.color }}>{offer.label}</h4>
        )}
        <h5 className="offer-name">
          {offer.RenewableContent === 100 && (
            <FontAwesomeIcon
              className="green-icon leaf"
              icon={['fa', 'leaf']}
            />
          )}

          {offer.DisplayName}
        </h5>

        <div className="price-container">
          {priceColumn === 'TrueCost_Dollars' ? (
            <div className="price">
              {offer.TrueCost_PerKWh.toFixed(1)}
              <sup>¢</sup>
            </div>
          ) : (
            <div className="price">
              {offer.SingleMonthPriceKWh.toFixed(1)}
              <sup>¢</sup>
            </div>
          )}

          <div className="usage">
            per kWh {priceColumn === 'TrueCost_Dollars' ? ' a ' : ''}
            {priceColumn === 'TrueCost_Dollars'
              ? ' seasonal average of ' + usage + ' kWh'
              : ' at ' + usage + ' kWh'}
            <br />
            {offer.TermLength > 1
              ? offer.TermLength + ' months'
              : 'Monthly'}{' '}
            {' / ' + offerCalc.getRateType(offer)}
          </div>
        </div>

        <div className="logo-action">
          <ProviderLogo offer={offer} />
        </div>

        <div className="action">
          <OrderButton offer={offer} />
        </div>

        <div className="plan-details">
          {offer.calcs &&
          offer.calcs.creditList &&
          offer.calcs.creditList.length &&
          offer.calcs.creditList[0].amount &&
          offer.calcs.creditList[0].isTiered
            ? '$' +
              Math.abs(offer.calcs.creditList[0].amount).toFixed(2) +
              ' Bill Credit - '
            : ''}
          <Button onClick={() => setShowOfferDetails(!showOfferDetails)}>
            {offer.label ? 'View ' : ''} Plan Details{' '}
            {!offer.label && (
              <KeyboardArrowDownIcon
                style={{ transform: showOfferDetails ? 'rotate(180deg)' : '' }}
              />
            )}
          </Button>
        </div>

        {showOfferDetails && (
          <div className="mobile-only">
            <OfferRowElectric
              offer={offer}
              showDetails={true}
              selectedUsage={usage}
              usageSetting={usageSetting}
              closeFunc={() => setShowOfferDetails(null)}
            />
          </div>
        )}
      </div>

      {showOfferDetails && offer.label && (
        <div className="hide-mobile">
          <OfferDetailsPopup
            offer={offer}
            closeFunc={() => setShowOfferDetails(null)}
            selectedUsage={usage}
            usageSetting={usageSetting}
          />
        </div>
      )}
    </li>
  ) : null;
}

export default FeaturedOffer;
