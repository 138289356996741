import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Scrollbars } from 'react-custom-scrollbars';
import { renderTrackVerticalDefault } from 'react-custom-scrollbars/lib/Scrollbars/defaultRenderElements';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import * as dataApi from '../../api/dataApi';

import ConnectSmartMeterButton from '../OfferGrid/ConnectSmartMeterButton';
import UsageHistoryForm from '../UsageHistoryEntry/UsageHistoryForm';

import './FiltersElectric.css';
import { providerLogoBaseUrl } from '../OfferGrid/ProviderLogo';

function UsageSelection({
  usageSetting,
  setUsageSetting,
  estimatedUsage,
  setEstimatedUsage,
  selectedUsage,
  setSelectedUsage,
  serviceAddress,
  usageHistory,
  setUsageHistory,
  setShowUsageHistoryRequest,
  lastBillUsage,
  setLastBillUsage,
  lastBillAmount,
  setLastBillAmount,
  usageHistoryMode,
  setUsageHistoryMode,
  setShowUsageHistoryPrompt,
  setLastSelectedUsage,
  setFiltersExpanded,
  tooltips,
  quickFilter,
  setQuickFilter
}) {
  const [showUsageHistoryForm, setShowUsageHistoryForm] = useState(false);
  const smtEnabled = false;
  const [showCustom, setShowCustom] = useState(
    true
    //selectedUsage !== 500 && selectedUsage !== 1000 && selectedUsage !== 2000
  );

  useEffect(() => {
    if (showCustom) {
      //setCookie('sc-show-custom', '1');
    }
  }, [showCustom]);

  return (
    <div className="usage-selection">
      <div className="content-area step-one">
        <h5>Choose Your Display Preference:</h5>

        <Button
          onClick={() => setQuickFilter('all')}
          className={quickFilter !== 'no-gimmicks' ? 'selected' : ''}>
          Show ALL Plans
        </Button>
        <Button
          onClick={() => setQuickFilter('no-gimmicks')}
          className={quickFilter === 'no-gimmicks' ? 'selected' : ''}>
          Show ONLY Fixed Rate Plans
          <br />
          No Pricing Gimmicks
        </Button>
      </div>

      <div className="content-area estimated">
        <h6 style={{ marginBottom: '15px' }}>+</h6>
        <h5>Choose Your Usage Estimate:</h5>

        <div className="button-group">
          <Button
            onClick={() => {
              setSelectedUsage(500);
              setLastSelectedUsage(500);
              setUsageSetting('estimated');
              setShowUsageHistoryForm(false);
            }}
            variant="outlined"
            className={
              selectedUsage === 500 && selectedUsage !== estimatedUsage
                ? 'selected'
                : ''
            }>
            <img
              className="small"
              src={withPrefix('/images/misc/apartment.svg')}
            />
            Apartment
            <strong>500 kWh</strong>
          </Button>

          <Button
            onClick={() => {
              setSelectedUsage(1000);
              setLastSelectedUsage(1000);
              setUsageSetting('estimated');
              setShowUsageHistoryForm(false);
            }}
            variant="outlined"
            className={
              selectedUsage === 1000 &&
              selectedUsage !== estimatedUsage &&
              usageSetting !== 'actual'
                ? 'selected'
                : ''
            }>
            <img
              className="medium"
              src={withPrefix('/images/misc/house-icon-blue.svg')}
            />
            Small House
            <strong>1000 kWh</strong>
          </Button>

          <Button
            onClick={() => {
              setSelectedUsage(2000);
              setLastSelectedUsage(2000);
              setUsageSetting('estimated');
              setShowUsageHistoryForm(false);
            }}
            variant="outlined"
            className={
              selectedUsage === 2000 && selectedUsage !== estimatedUsage
                ? 'selected'
                : ''
            }>
            <img className="large" src={withPrefix('/images/misc/house.svg')} />
            Large House
            <strong>2000 kWh</strong>
          </Button>
        </div>
      </div>

      <div className="secondary-options">
        <h6>OR</h6>

        {showCustom ? (
          <>
            {serviceAddress &&
            serviceAddress.Address &&
            serviceAddress.Address.ResidentID &&
            serviceAddress.Address.PropertyName ? (
              <div className="apartment-resident">
                <Button
                  onClick={() => {
                    setSelectedUsage(661);
                    setLastSelectedUsage(661);
                    setUsageSetting('estimated');
                    setShowUsageHistoryForm(false);
                  }}
                  variant="outlined"
                  className={
                    selectedUsage === 661 && selectedUsage !== estimatedUsage
                      ? 'selected'
                      : ''
                  }>
                  Average Usage at {serviceAddress.Address.PropertyName}
                  <strong> 661 kWh</strong>
                </Button>
              </div>
            ) : (
              <label>
                Average Apartment
                <Button
                  onClick={() => {
                    setSelectedUsage(661);
                    setLastSelectedUsage(661);
                    setUsageSetting('estimated');
                    setShowUsageHistoryForm(false);
                  }}
                  variant="outlined"
                  className={
                    selectedUsage === 661 && selectedUsage !== estimatedUsage
                      ? 'selected'
                      : ''
                  }>
                  661 kWh
                </Button>
              </label>
            )}

            <label>
              Overall Texas Average
              <Button
                onClick={() => {
                  setSelectedUsage(1145);
                  setLastSelectedUsage(1145);
                  setUsageSetting('estimated');
                  setShowUsageHistoryForm(false);
                }}
                variant="outlined"
                className={
                  selectedUsage === 1145 && selectedUsage !== estimatedUsage
                    ? 'selected'
                    : ''
                }>
                1145 kWh
              </Button>
            </label>

            <label>
              Enter Your Average Usage
              <Button
                onClick={() => {
                  if (estimatedUsage) {
                    setSelectedUsage(estimatedUsage);
                    setLastSelectedUsage(estimatedUsage);
                  }
                  setShowUsageHistoryForm(true);
                  document.getElementById('estimateUsageAmount').focus();
                }}
                variant="outlined"
                className={
                  'estimated' +
                  (selectedUsage === estimatedUsage ? ' selected' : '')
                }>
                <div>
                  <TextField
                    value={estimatedUsage}
                    variant="standard"
                    onChange={(event) => {
                      if (event.target.value) {
                        setEstimatedUsage(
                          parseFloat(event.target.value.substring(0, 5))
                        );

                        setLastSelectedUsage(
                          parseFloat(event.target.value.substring(0, 5))
                        );
                      } else {
                        setEstimatedUsage(null);
                      }
                    }}
                    id="estimateUsageAmount"
                    key="estimateUsageAmount"
                    onFocus={(event) => event.target.select()}
                    placeholder="Avg"
                    type="number"
                    inputProps={{ inputMode: 'numeric', maxLength: 5 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">kWh</InputAdornment>
                      )
                    }}
                  />
                </div>
              </Button>
            </label>
          </>
        ) : (
          <div className="show-custom">
            <a className="link" onClick={() => setShowCustom(true)}>
              Click here
            </a>{' '}
            here to use a custom usage
          </div>
        )}
      </div>

      {showUsageHistoryForm ? (
        <div className="content-area">
          {usageHistoryMode || !smtEnabled ? (
            <p>
              Enter your 12-month average usage above, or enter your
              month-by-month usage below.
              <br />
              <UsageHistoryForm
                usageHistory={usageHistory}
                setUsageHistory={setUsageHistory}
                onUsageChanged={() => setUsageSetting('actual')}
              />
            </p>
          ) : (
            <div className="actual-usage-option">
              <p>
                If you’d like to see the{' '}
                <Tooltip title={tooltips.TrueCost} color="primary">
                  <a className="tooltip">True Cost</a>
                </Tooltip>{' '}
                of these plans based on your actual usage, we can pull your
                history electronically from Smart Meter Texas, or you can enter
                it yourself by clicking below.
              </p>
              <div className="usage-history-options">
                <ConnectSmartMeterButton
                  setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUsageHistoryMode('manual')}>
                  Enter Manually
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : usageSetting === 'last-bill' ? (
        <div className="content-area estimated last-bill-option">
          <p>
            Enter your last bill amounts below to compare plans based on how
            much you will save. For an even more accurate comparison,{' '}
            <a className="link" onClick={() => setUsageSetting('actual')}>
              enter your last 12 months of usage
            </a>
            .
          </p>
          {/* <Button
              onClick={() => {
                //open address lookup
              }}
              variant="outlined">
              <div>Zip Code</div>
              <div>
                <TextField value={serviceAddress.Address.Zip} disabled />
              </div>
            </Button> */}
          <div className="button-group">
            <Button
              onClick={() => {
                document.getElementById('lastBillAmount').focus();
              }}
              variant="outlined">
              <div>Last Bill Amount</div>
              <div>
                <TextField
                  value={lastBillAmount}
                  onChange={(event) => {
                    if (event.target.value) {
                      setLastBillAmount(parseFloat(event.target.value));
                    } else {
                      setLastBillAmount(null);
                    }
                  }}
                  variant="standard"
                  id="lastBillAmount"
                  key="lastBillAmount"
                  autoFocus={!lastBillAmount}
                  onFocus={(event) => event.target.select()}
                  type="number"
                  inputProps={{ inputMode: 'numeric' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </div>
            </Button>
            <Button
              onClick={() => {
                if (lastBillUsage) {
                  setSelectedUsage(lastBillUsage);
                }

                if (typeof window !== 'undefined') {
                  document.getElementById('lastBillUsage').focus();
                }
              }}
              variant="outlined">
              <div>Last Bill Usage</div>
              <div>
                <TextField
                  value={lastBillUsage}
                  onChange={(event) => {
                    if (event.target.value) {
                      setLastBillUsage(
                        parseFloat(event.target.value.substring(0, 5))
                      );
                    } else {
                      setLastBillUsage(null);
                    }
                  }}
                  variant="standard"
                  id="lastBillUsage"
                  key="lastBillUsage"
                  autoFocus={lastBillAmount && !lastBillUsage}
                  onFocus={(event) => event.target.select()}
                  type="number"
                  inputProps={{ inputMode: 'numeric', maxLength: 5 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kWh</InputAdornment>
                    )
                  }}
                />
              </div>
            </Button>
          </div>
          <div
            className={
              'last-bill-notice' +
              (lastBillAmount && lastBillUsage ? ' populated' : '')
            }>
            <InfoIcon />
            {!lastBillAmount || !lastBillUsage ? (
              <>
                <a
                  className="link"
                  onClick={() =>
                    document.getElementById('lastBillAmount').focus()
                  }>
                  Last Bill Amount
                </a>{' '}
                and{' '}
                <a
                  className="link"
                  onClick={() =>
                    document.getElementById('lastBillUsage').focus()
                  }>
                  Last Usage Amount
                </a>{' '}
                are both required
              </>
            ) : (
              <>
                Scroll down to view plans
                <Button
                  className="filters-secondary-close"
                  fullWidth={true}
                  variant="outlined"
                  onClick={() => setFiltersExpanded(false)}>
                  Close Filters
                </Button>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function getContentHeight() {
  const contentHeight =
    (typeof window !== 'undefined' && window.innerHeight) ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  return contentHeight - 120;
}

function FiltersElectric({
  filtersExpanded,
  usageSetting,
  setUsageSetting,
  estimatedUsage,
  setEstimatedUsage,
  selectedUsage,
  setSelectedUsage,
  serviceAddress,
  usageHistory,
  setUsageHistory,
  setShowUsageHistoryRequest,
  lastBillUsage,
  setLastBillUsage,
  lastBillAmount,
  setLastBillAmount,
  usageHistoryMode,
  setUsageHistoryMode,
  setShowUsageHistoryPrompt,
  setLastSelectedUsage,
  setFiltersExpanded,
  allOptionSelected,
  selectedRateType,
  selectedTermLength,
  filterCounts,
  selectedProviderList,
  tooltips,
  setSelectedRateType,
  setSelectedTermLength,
  setSelectedProviderList,
  setAllOptionSelected,
  setFiltersClicked,
  quickFilterList,
  quickFilter,
  setQuickFilter,
  mobile,
  offerList
}) {
  const [showQuickFiltersMenu, setShowQuickFiltersMenu] = useState(null);

  function getTermLengthKeys() {
    let termLengthList = Object.keys(selectedTermLength);

    termLengthList.sort((a, b) => {
      let valueA = a === 'Monthly' ? 1 : parseInt(a.split(' ')[0]);
      let valueB = b === 'Monthly' ? 1 : parseInt(b.split(' ')[0]);

      let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      return result;
    });

    return termLengthList;
  }

  function getProviderKeys() {
    let providerList = Object.keys(selectedProviderList);

    providerList.sort((a, b) => {
      let result = a > b ? 1 : a < b ? -1 : 0;

      return result;
    });

    return providerList;
  }

  return (
    <>
      <UsageSelection
        usageSetting={usageSetting}
        setUsageSetting={setUsageSetting}
        estimatedUsage={estimatedUsage}
        setEstimatedUsage={setEstimatedUsage}
        selectedUsage={selectedUsage}
        setSelectedUsage={setSelectedUsage}
        serviceAddress={serviceAddress}
        usageHistory={usageHistory}
        setUsageHistory={setUsageHistory}
        setShowUsageHistoryRequest={setShowUsageHistoryRequest}
        lastBillUsage={lastBillUsage}
        setLastBillUsage={setLastBillUsage}
        lastBillAmount={lastBillAmount}
        setLastBillAmount={setLastBillAmount}
        usageHistoryMode={usageHistoryMode}
        setUsageHistoryMode={setUsageHistoryMode}
        setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
        setLastSelectedUsage={setLastSelectedUsage}
        setFiltersExpanded={setFiltersExpanded}
        tooltips={tooltips}
        quickFilter={quickFilter}
        setQuickFilter={setQuickFilter}
      />

      <h4>
        {mobile ? (
          <div className="quick-filters-menu">
            <Button
              aria-controls="quick-filters-menu"
              aria-haspopup="true"
              onClick={(event) => setShowQuickFiltersMenu(event.currentTarget)}>
              {quickFilter === 'all'
                ? 'All Plans'
                : quickFilterList.filter(
                    (_quickFilter) => _quickFilter.value === quickFilter
                  )[0].label}{' '}
              <ExpandMoreIcon />
            </Button>
            <Menu
              id="quick-filters-menu"
              anchorEl={showQuickFiltersMenu}
              keepMounted
              open={Boolean(showQuickFiltersMenu)}
              onClose={() => setShowQuickFiltersMenu(null)}>
              {quickFilterList
                .filter((_quickFilter) => !_quickFilter.hideInQuickFilters)
                .map((_quickFilter) =>
                  !_quickFilter.requiredRateType ||
                  (selectedRateType &&
                    selectedRateType.hasOwnProperty(
                      _quickFilter.requiredRateType
                    )) ? (
                    <MenuItem
                      onClick={() => {
                        setQuickFilter(_quickFilter.value);
                        setShowQuickFiltersMenu(null);
                      }}>
                      {_quickFilter.value === 'all'
                        ? 'All Plans'
                        : _quickFilter.label}
                    </MenuItem>
                  ) : null
                )}
            </Menu>
          </div>
        ) : null}

        <Button onClick={() => setFiltersExpanded(!filtersExpanded)}>
          <FontAwesomeIcon icon={['fa', 'sliders-h']} /> More Filters{' '}
          <ExpandMoreIcon />
        </Button>
      </h4>

      {selectedTermLength ? (
        <div className="filters-set">
          <FormControl component="fieldset">
            <FormLabel component="legend">Term (Contract Length)</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allOptionSelected.termLength === true}
                    onChange={() =>
                      setAllOptionSelected({
                        ...allOptionSelected,
                        termLength: !allOptionSelected.termLength
                      })
                    }
                    name="ALL"
                    color="primary"
                    disabled={allOptionSelected.termLength}
                  />
                }
                label="ALL"
              />

              {getTermLengthKeys().map((termLength) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !allOptionSelected.termLength &&
                        selectedTermLength[termLength] === true
                      }
                      onChange={() => {
                        setSelectedTermLength({
                          ...selectedTermLength,
                          [termLength]:
                            selectedTermLength[termLength] === true
                              ? false
                              : true
                        });
                        setFiltersClicked(true);
                      }}
                      name={termLength}
                      color="primary"
                    />
                  }
                  label={
                    termLength +
                    (filterCounts && filterCounts.termLength
                      ? ' (' + filterCounts.termLength[termLength] + ')'
                      : '')
                  }
                  disabled={
                    !filterCounts ||
                    !filterCounts.termLength ||
                    !filterCounts.termLength[termLength]
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      ) : null}

      {quickFilterList ? (
        <div className="filters-set">
          <FormControl component="fieldset">
            <FormLabel component="legend">Specific Plan Features</FormLabel>
            <FormGroup>
              {quickFilterList
                .filter(
                  (_quickFilter) =>
                    !_quickFilter.hideInQuickFilters &&
                    (!_quickFilter.requiredRateType ||
                      (selectedRateType &&
                        selectedRateType.hasOwnProperty(
                          _quickFilter.requiredRateType
                        ))) &&
                    (!_quickFilter.func ||
                      _quickFilter.func(
                        offerList,
                        selectedTermLength,
                        allOptionSelected
                      ).length)
                )
                .map((_quickFilter) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={_quickFilter.value === quickFilter}
                        onChange={() => {
                          setQuickFilter(_quickFilter.value);
                          setFiltersClicked(true);
                        }}
                        name={_quickFilter.label}
                        color="primary"
                      />
                    }
                    disabled={
                      _quickFilter.value === 'all' && quickFilter === 'all'
                    }
                    label={
                      _quickFilter.label +
                      (_quickFilter.func
                        ? ' (' +
                          _quickFilter.func(
                            offerList,
                            selectedTermLength,
                            allOptionSelected
                          ).length +
                          ')'
                        : '')
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>
        </div>
      ) : null}

      {selectedProviderList ? (
        <div className="filters-set provider">
          {offerList &&
            offerList.length &&
            getProviderKeys().map((providerName) => (
              <div className="provider-button-container">
                <Button
                  className={
                    'provider-button' +
                    (selectedProviderList[providerName] ? ' selected' : '')
                  }
                  onClick={() => {
                    setSelectedProviderList({
                      ...selectedProviderList,
                      [providerName]:
                        selectedProviderList[providerName] === true
                          ? false
                          : true
                    });

                    setFiltersClicked(true);
                  }}>
                  <img
                    src={
                      providerLogoBaseUrl +
                      offerList.filter(
                        (offer) => offer.ProviderName === providerName
                      )[0].LogoFile
                    }
                    alt={providerName}
                  />
                </Button>
              </div>
            ))}
          {/* <FormControl component="fieldset">
            <FormLabel component="legend">Provider</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allOptionSelected.provider === true}
                    onChange={() =>
                      setAllOptionSelected({
                        ...allOptionSelected,
                        provider: !allOptionSelected.provider
                      })
                    }
                    name="ALL"
                    color="primary"
                    disabled={allOptionSelected.provider}
                  />
                }
                label="ALL"
              />

              {offerList &&
                offerList.length &&
                getProviderKeys().map((providerName) => (

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !allOptionSelected.provider &&
                          selectedProviderList[providerName] === true
                        }
                        onChange={() => {
                          setSelectedProviderList({
                            ...selectedProviderList,
                            [providerName]:
                              selectedProviderList[providerName] === true
                                ? false
                                : true
                          });

                          setFiltersClicked(true);
                        }}
                        color="primary"
                        name={providerName}
                      />
                    }
                    label={
                      providerName +
                      (filterCounts && filterCounts.provider
                        ? ' (' + filterCounts.provider[providerName] + ')'
                        : '')
                    }
                    disabled={
                      !filterCounts ||
                      !filterCounts.provider ||
                      !filterCounts.provider[providerName]
                    }
                  />
                ))}
            </FormGroup>
          </FormControl> */}
        </div>
      ) : null}

      {/* {selectedRateType ? (
        <div className="filters-set">
          <FormControl component="fieldset">
            <FormLabel component="legend">Rate Type:</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allOptionSelected.rateType === true}
                    onChange={() =>
                      setAllOptionSelected({
                        ...allOptionSelected,
                        rateType: !allOptionSelected.rateType
                      })
                    }
                    name="ALL"
                    color="primary"
                    disabled={allOptionSelected.rateType}
                  />
                }
                label="ALL"
              />

              {Object.keys(selectedRateType).map((rateType) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !allOptionSelected.rateType &&
                        selectedRateType[rateType] === true
                      }
                      onChange={() => {
                        setSelectedRateType({
                          ...selectedRateType,
                          [rateType]:
                            selectedRateType[rateType] === true ? false : true
                        });
                        setFiltersClicked(true);
                      }}
                      name={rateType}
                      color="primary"
                    />
                  }
                  label={
                    rateType +
                    (filterCounts && filterCounts.rateType
                      ? ' (' + filterCounts.rateType[rateType] + ')'
                      : '')
                  }
                  disabled={
                    !filterCounts ||
                    !filterCounts.rateType ||
                    filterCounts.rateType[rateType] === 0
                  }
                />
              ))}
            </FormGroup>
          </FormControl>

          <Button
            className="filters-secondary-close"
            fullWidth={true}
            variant="outlined"
            onClick={() => {
              setFiltersExpanded(false);
              setTimeout(() => {
                if (typeof window !== 'undefined') {
                  const top = document.getElementById('offerRowStart');
                  if (top) {
                    dataApi.scrollToElementInPage(top, -145, false);
                  }
                }
              }, 200);
            }}>
            Close Filters
          </Button>
        </div>
      ) : null} */}
    </>
  );
}

function FiltersElectricWrapper(props) {
  const [scrollbarHeight, setScrollbarHeight] = useState(getContentHeight());

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => setScrollbarHeight(getContentHeight()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <>
      {props.mobile ? (
        <div className="mobile-filters-container">
          <div
            className={
              'filters-container-outer small ' +
              (props.filtersExpanded ? ' expanded' : '')
            }>
            {/* <div className="count">
Showing {filteredOfferList.length} of {offerList.length}{' '}
offers
</div> */}

            <div className="controls">
              <FiltersElectric {...props} mobile />
            </div>
          </div>
        </div>
      ) : null}

      {!props.mobile ? (
        <div
          className={
            'filters-container-outer desktop ' +
            (props.filtersExpanded ? ' expanded' : '')
          }>
          {/* <div className="count">
Showing {filteredOfferList.length} of {offerList.length}{' '}
offers
</div> */}

          <div className="controls with-scroll">
            <Scrollbars
              autoHide={true}
              style={{ height: scrollbarHeight }}
              renderTrackHorizontal={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    visibility: 'hidden',
                    display: 'none'
                  }}
                />
              )}
              renderTrackVertical={(props) =>
                renderTrackVerticalDefault({
                  ...props,
                  className: 'track-vertical'
                })
              }>
              <FiltersElectric {...props} />
            </Scrollbars>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default FiltersElectricWrapper;
