import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFullCostDetail } from '../../api/offerCalc';

import FeaturedOfferElectric from '../OfferGrid/FeaturedOfferElectric';

export function FeaturedOffersElectric({ serviceAddress }) {
  const [featuredOfferList, setFeaturedOfferList] = useState(null);
  const [priceColumn, setPriceColumn] = useState('PriceAt1000');
  const [usage, setUsage] = useState(1000);
  const [usageSetting, setUsageSetting] = useState('estimated');
  useEffect(() => {
    console.log('usage changed 2', usage, priceColumn);

    if (serviceAddress && serviceAddress.OfferDictionary) {
      if (serviceAddress.OfferDictionary.Electric) {
        let _offerList = serviceAddress.OfferDictionary.Electric.filter(
          (offer) => offer.TermLength >= 12
        ).map((offer) =>
          getFullCostDetail(
            offer,
            usage,
            serviceAddress,
            null,
            'estimated',
            null
          )
        );

        // if (typeof window !== 'undefined' && window.innerWidth > 820) {
        //   _offerList = _offerList.filter((offer) => offer.ApiID !== 603);
        // }

        _offerList.sort(
          (offerA, offerB) => offerA[priceColumn] - offerB[priceColumn]
        );

        const bestAdvertisedPriceList = _offerList.filter(
          (offer) => offer[priceColumn] === _offerList[0][priceColumn]
        );

        const bestAdvertisedPrice =
          bestAdvertisedPriceList.length === 1
            ? { ...bestAdvertisedPriceList[0] }
            : {
                ...bestAdvertisedPriceList[
                  Math.floor(Math.random() * bestAdvertisedPriceList.length)
                ]
              };

        const mostPopular = _offerList.filter(
          (_offer) => _offer.ProviderID === 75593
        )[0]; //Already sorted by advertised price

        const bestPrepaid = {
          ..._offerList.filter((offer) => offer.IsPrepaid)[0]
        };

        _offerList.sort(
          (offerA, offerB) => offerA.TrueCost_Dollars - offerB.TrueCost_Dollars
        );

        const bestTruePrice = { ..._offerList[0] };

        _offerList.sort(
          (offerA, offerB) =>
            offerB.RenewableContent - offerA.RenewableContent ||
            offerA[priceColumn] - offerB[priceColumn]
        );

        const bestPercentRenewable = { ..._offerList[0] };

        if (mostPopular) {
          setFeaturedOfferList([
            {
              label: 'Lowest Advertised Price',
              color: '#ed7d31',
              ...bestAdvertisedPrice
            },
            {
              label: 'Most Popular Brand',
              color: '#0070c0',
              ...mostPopular
            },
            {
              label: '100% Renewable',
              color: '#00b050',
              ...bestPercentRenewable
            },
            {
              label: 'Best Year Round Value',
              color: '#7030a0',
              ...bestTruePrice
            }
          ]);
        } else {
          setFeaturedOfferList([
            {
              label: 'Lowest Advertised Price',
              color: '#ed7d31',
              ...bestAdvertisedPrice
            },
            {
              label: 'Best Year Round Value',
              color: '#0070c0',
              ...bestTruePrice,
              isTrueCost: true
            },
            {
              label: '100% Renewable',
              color: '#00b050',
              ...bestPercentRenewable
            },
            {
              label: 'Best Prepaid - No Deposit Plan',
              color: '#7030a0',
              ...bestPrepaid
            }
          ]);
        }
      } else {
        setFeaturedOfferList([]);
      }
    }
  }, [serviceAddress, priceColumn, usage]);

  return featuredOfferList ? (
    <ul className="featured-offer-list">
      {featuredOfferList.map((offer) => (
        <FeaturedOfferElectric
          offer={offer}
          priceColumn={offer.isTrueCost ? 'TrueCost_Dollars' : priceColumn}
          usage={usage}
          usageSetting={usageSetting}
        />
      ))}
    </ul>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedOffersElectric);
