import React, { useState, useEffect, useRef } from 'react';
import { withPrefix } from 'gatsby-link';

import Loadable from '@loadable/component';
import Slider from '@mui/material/Slider';

import {
  _dontBeAverageChartOptions,
  _offerChartSeries,
  _offerChartData
} from './index';

export default function ({ inline }) {
  const [dontBeAverageChartOptions, setDontBeAverageChartOptions] =
    useState(null);

  const [sliderUsage, setSliderUsage] = useState(
    _offerChartData.filter((price) => price.usage === 1000)[0]
  );

  const autoSliderInterval = useRef();
  const autoSliderValue = useRef();
  const [sliderClicked, setSliderClicked] = useState(false);

  useEffect(() => {
    if (document.getElementById('zipCode')) {
      document.getElementById('zipCode').focus();
    }

    autoSliderInterval.current = setInterval(() => {
      if (sliderUsage) {
        const lastUsage = autoSliderValue.current
          ? autoSliderValue.current
          : sliderUsage.usage;
        let autoSlideUsage;

        switch (lastUsage) {
          case 1000:
            autoSlideUsage = 700;
            break;
          case 700:
            autoSlideUsage = 1500;
            break;
          default:
            autoSlideUsage = 1000;
            break;
        }

        autoSliderValue.current = autoSlideUsage;

        setSliderUsage(
          _offerChartData.filter((price) => price.usage === autoSlideUsage)[0]
        );
      }
    }, 3000);

    return () => {
      if (autoSliderInterval.current) {
        clearInterval(autoSliderInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderUsage) {
      setDontBeAverageChartOptions({
        ..._dontBeAverageChartOptions,
        annotations: {
          xaxis: [
            {
              x: sliderUsage.lowUsage,
              x2: sliderUsage.highUsage,
              borderColor: '#337eff',
              fillColor: '#bbd4ff'
              // label: {
              //   text:
              //     'Low of ' +
              //     _selectedPrice.lowUsage +
              //     ' kWh / High of ' +
              //     _selectedPrice.highUsage +
              //     ' kWh'
              // }
            }
          ]
        }
      });
    }
  }, [sliderUsage]);

  function getRangePosition() {
    let position = (sliderUsage.usage / 2400) * 100;
    const maxPosition = window && window.innerWidth > 668 ? 80 : 60;
    const minPosition = window && window.innerWidth > 668 ? 12 : 20;

    if (position > maxPosition) {
      position = maxPosition;
    } else {
      if (position < minPosition) {
        position = minPosition;
      }
    }

    return position;
  }

  function getSliderArrowStyle() {
    let width = ((sliderUsage.highUsage - sliderUsage.lowUsage) / 2400) * 100;

    if (window && window.innerWidth < 668) {
      if (width < 10) {
        width = 10;
      }
    }

    let style = {
      left: (sliderUsage.lowUsage / 2400) * 100 + '%',
      width: width + '%'
    };

    return style;
  }

  return (
    <div className="article article-3">
      <div className="article-anchor" id="article3"></div>
      <div className="main">
        <h2>Don't Be Average</h2>
        <div className="text-block">
          <div className="chart">
            {dontBeAverageChartOptions ? (
              <>
                <p className="chart-summary">
                  The price schedule below is based on a plan with a fixed
                  energy price of 24.5¢ per kilowatt hour and a $100 bill credit
                  at 1,000 kilowatts, resulting in an{' '}
                  <strong>advertised price</strong> of <strong>14.5¢</strong> at{' '}
                  <strong>1000 kWh</strong>.
                </p>

                <div className="chart-container">
                  <LoadableChart
                    key="dontBeAverageChart1"
                    options={dontBeAverageChartOptions}
                    series={_offerChartSeries}
                    type="line"
                    width={inline ? '500px' : '700px'}
                    height="400px"
                    className="hide-mobile"
                  />
                  <LoadableChart
                    key="dontBeAverageChart2"
                    options={dontBeAverageChartOptions}
                    series={_offerChartSeries}
                    type="line"
                    width="320px"
                    height="350px"
                    className="mobile-only"
                  />

                  <div className="hand chart-text">
                    <span className="state-average">Advertised price</span>

                    <div className="slider-arrow-container">
                      <div
                        className="slider-arrow"
                        style={getSliderArrowStyle()}>
                        <img
                          src={withPrefix('/images/arrows/arrow-head-left.svg')}
                          alt="Arrow Head (Left)"
                          className="head left"
                        />

                        <img
                          src={withPrefix(
                            '/images/arrows/arrow-head-right.svg'
                          )}
                          alt="Arrow Head (Right)"
                          className="head right"
                        />
                      </div>
                    </div>

                    <span
                      className="bottom-text"
                      style={{
                        left: getRangePosition() + '%'
                      }}>
                      <u>Seasonal Range </u>
                      <br />
                      Average: {sliderUsage.usage}
                      <br />
                      Low: {sliderUsage.lowUsage}&nbsp;&nbsp; High:{' '}
                      {sliderUsage.highUsage}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    'chart-bottom' + (sliderClicked ? '' : ' not-clicked')
                  }>
                  <Slider
                    aria-label="Usage"
                    defaultValue={1000}
                    value={sliderUsage.usage}
                    getAriaValueText={(value) => value + ' kWh'}
                    valueLabelDisplay="auto"
                    step={25}
                    marks
                    min={100}
                    max={2400}
                    onChange={(event, newValue) => {
                      setSliderClicked(true);

                      setSliderUsage(
                        _offerChartData.filter(
                          (price) => price.usage === newValue
                        )[0]
                      );

                      if (autoSliderInterval.current) {
                        clearInterval(autoSliderInterval.current);
                      }
                    }}
                  />

                  <ul>
                    <li>
                      <div className="label">12-Month Average Usage</div>
                      <div className="value">{sliderUsage.usage} kWh</div>
                      <div className="low-high">
                        <span>
                          Low: <strong>{sliderUsage.lowUsage}</strong>
                        </span>
                        <span>
                          High: <strong>{sliderUsage.highUsage}</strong>
                        </span>
                      </div>
                    </li>

                    <li>
                      <div className="label">Average "True Price"</div>
                      <div className="value">
                        {sliderUsage.trueCost_PerKWh.toFixed(1)}¢
                      </div>
                      <div className="low-high">
                        <span>
                          Low:{' '}
                          <strong>{sliderUsage.lowPrice.toFixed(1)}¢</strong>
                        </span>
                        <span>
                          High:{' '}
                          <strong>{sliderUsage.highPrice.toFixed(1)}¢</strong>
                        </span>
                      </div>
                    </li>

                    <li>
                      <div className="label">Average "True Cost"</div>
                      <div className="value">
                        ${sliderUsage.trueCost_Dollars.toFixed(2)}
                      </div>
                      <div className="low-high">
                        <span>
                          Low:{' '}
                          <strong>${sliderUsage.lowDollars.toFixed(2)}</strong>
                        </span>
                        High:{' '}
                        <span>
                          <strong>${sliderUsage.highDollars.toFixed(2)}</strong>
                        </span>
                      </div>
                    </li>
                  </ul>

                  <p className="credit-summary">
                    This plan has a{' '}
                    <strong>
                      $100 Bill Credit{' '}
                      <span className="keep-together">
                        if usage is > 1000 kWh
                      </span>
                    </strong>
                  </p>
                  <p className="credit-summary">
                    Credit would have applied in{' '}
                    <strong className="keep-together">
                      {sliderUsage.creditCount} of the past 12 months.
                    </strong>
                  </p>

                  <p>
                    Using the Seasonal Usage Curve above, the average consumer
                    would have received the bill credit in{' '}
                    {sliderUsage.creditCount} of the past 12 months, resulting
                    in an annual average price (True Price) of{' '}
                    {sliderUsage.trueCost_PerKWh.toFixed(1)}¢ per kilowatt hour.
                  </p>
                </div>
              </>
            ) : null}
          </div>

          <h4>
            The average Texan overpays for electricity by about 15% because they
            are led to believe that the advertised price they see elsewhere is
            the true price of that plan.
          </h4>
          <p>
            It’s <strong>not. </strong>In reality, those 500-1000-2000 kilowatt
            prices advertised on the EFL are really quite useless when it comes
            to finding your best deal.
          </p>
          <p>
            The only way to truly find your best deal is to do the necessary
            math and convert ALL available plan options to a common value, a
            “True Price” if you will, that accurately represents what each plan
            will likely cost you over the next 12 months based on a Seasonally
            Adjusted Usage Profile.
          </p>
          <p>
            The sliding usage bar on the chart to the right is a visual
            representation of the math it takes to find each plans True Cost. As
            you move the sliding usage bar back and forth, the True Price and
            True Cost change to show you what your average costs will be over a
            12-month period.
          </p>
          <p>
            This shows you that within some usage profiles this type of plan may
            still a good deal, but at other lower ranges, you’ll pay
            significantly more than advertised. At no point does anyone pay the
            Advertised Price for the full year.
          </p>
          <p>
            Our platform automatically performs these calculations for you, on
            all plans at all usage levels, because all plans are like this, good
            for some consumers, not so good for others.
          </p>
        </div>
      </div>
    </div>
  );
}

const LoadableChart = Loadable(() => import('react-apexcharts'));
