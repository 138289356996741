import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TextField } from '@mui/material';

import './index.css';

function UsageHistoryForm({
  monthList,
  usageHistory,
  setUsageHistory,
  onUsageChanged
}) {
  return (
    <div className="usage-history-form">
      <form>
        {monthList.map((month, monthIndex) => (
          <TextField
            label={month.substring(0, 3)}
            variant="outlined"
            id={'usageHistory_' + monthIndex}
            placeholder="kWh"
            // autoFocus={
            //   !usageHistory[month] &&
            //   (monthIndex === 0 || usageHistory[monthList[monthIndex - 1]])
            // }
            value={usageHistory[month]}
            onChange={(event) => {
              setUsageHistory({
                ...usageHistory,
                [month]: event.target.value
              });
              if (onUsageChanged) {
                onUsageChanged({
                  ...usageHistory,
                  [month]: event.target.value
                });
              }
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                const nextInput = document.getElementById(
                  'usageHistory_' + (monthIndex + 1)
                );
                if (nextInput) {
                  nextInput.focus();
                }
              }
            }}
          />
        ))}
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    monthList: state.commonData.monthList
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UsageHistoryForm);
