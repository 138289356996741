import React from 'react';
import { withPrefix } from 'gatsby-link';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function TermTypePopup({ closeFunc, title }) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="usage-history-request"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {title ? title : 'Term Length Information'}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <p>
          Did you know that you can cancel a long-term contract prior to its
          expiration date without penalty or early termination fees if you are
          moving to a new address?
        </p>
        <p>
          <a
            className="link"
            href="https://texreg.sos.state.tx.us/public/readtac$ext.TacPage?sl=R&app=9&p_dir=&p_rloc=&p_tloc=&p_ploc=&pg=1&p_tac=&ti=16&pt=2&ch=25&rl=475">
            Texas Customer Protection Rules
          </a>{' '}
          state:{' '}
          <em>
            "A contract is limited to service to a customer at a location
            specified in the contract.{' '}
            <strong>
              If the customer moves from the location, the customer is under no
              obligation to continue the contract at another location.
            </strong>{' '}
            The REP may require a customer to provide evidence that it is moving
            to another location. There must be no early termination fee assessed
            to the customer as a result of the customer's relocation if the
            customer provides a forwarding address and, if required, reasonable
            evidence that the customer no longer occupies the location specified
            in the contract."
          </em>
        </p>

        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              closeFunc();
            }}>
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TermTypePopup;
