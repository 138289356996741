import React, { useState } from 'react';
import { withPrefix } from 'gatsby-link';

import { stateUsageLastUpdated } from './index';

export default function ({}) {
  return (
    <div className="article article-2">
      <div className="article-anchor" id="article2"></div>
      <div className="main">
        <h2>Factoring in the Rollercoaster</h2>

        <div className="chart">
          <img
            src={withPrefix('/images/misc/Rollercoaster.gif?v=3')}
            alt="Seasonal Usage Curve"
          />
          <p>
            Average Monthly Usage per Residential Customer in Texas Source:{' '}
            <a
              href="https://www.eia.gov/electricity/monthly/"
              target="mi_offsite"
              className="link">
              EIA Electric Power Monthly
            </a>{' '}
            (Updated {stateUsageLastUpdated})
          </p>
        </div>
        <div className="text-block">
          <h4>
            Accounting for seasonal usage fluctuations is critical to finding
            your best price.
          </h4>
          <p>
            The most important, and often overlooked, factor in determining an
            electric plan’s “True Price” is the use of a Seasonally Adjusted
            Usage Profile.
          </p>
          <p>
            According to the Energy Information Administration (EIA), a typical
            residential consumer in Texas has used, on average, 1145 kilowatt
            hours (kWh) per month over the past 12 months.
          </p>
          <p>
            But choosing a plan based on just one number, whether it be the
            arbitrary 1000 kWh one that shopping sites typically use, the state
            average above or your own personal average, won’t result in you
            finding your best deal.
          </p>
          <p>
            To truly find your best deal you need to use the EIA’s Seasonally
            Adjusted Usage Profile for Texas, or better yet, your actual
            historical usage profile (click here to see how easy it is for us to
            get that for you) to estimate your monthly usage going forward. Then
            using the price schedule from each plan’s Electricity Facts Label
            (EFL), calculate that plan’s charges for each of the next 12-months
            to determine the plan’s “True Cost”. Then repeat that for all 100+
            plans that are available.
          </p>
          <p>
            Whoa, that’s a lot of math. Fortunately for you WE LOVE MATH, so let
            us do the nerdy part for you!
          </p>
        </div>
      </div>
    </div>
  );
}
