import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { withPrefix } from 'gatsby-link';

import { getRateType } from '../../api/offerCalc';

import OfferRow from './OfferRow';
import OfferRowElectric from './OfferRowElectric';
import './index.css';

function OfferDetailsPopup({
  offer,
  closeFunc,
  selectedUsage,
  usageSetting,
  fromFeatured
}) {
  const [rowOffer, setRowOffer] = useState(null);

  useEffect(() => {
    let _offer = { ...offer };

    _offer.RateType = getRateType(offer);

    setRowOffer(_offer);
  }, []);

  return rowOffer ? (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className={'offer-details-popup' + (fromFeatured ? ' hide-mobile' : '')}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {rowOffer.DisplayName}
      </DialogTitle>
      <DialogContent width="100%">
        <div className="offer-grid-container">
          <div className="offer-grid">
            {rowOffer.ProductTypeID === 6 ? (
              <OfferRowElectric
                offer={rowOffer}
                showDetails={true}
                selectedUsage={selectedUsage}
                usageSetting={usageSetting}
                closeFunc={closeFunc}
              />
            ) : (
              <OfferRow offer={offer} showDetails={true} />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default OfferDetailsPopup;
