import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { Button } from '@mui/material';
import { Alert } from '@material-ui/lab';
import LaunchIcon from '@mui/icons-material/Launch';

import ProviderLogo from '../OfferGrid/ProviderLogo';
import AddressSearch from '../AddressSearch';
import AddressSearchPopup from '../AddressSearch/AddressSearchPopup';

import '../OfferGrid/index.css';

function NoDeregulatedElectric({ serviceAddress, includeAddressSearch }) {
  const [showZipPopup, setShowZipPopup] = useState(null);
  return serviceAddress ? (
    <div className="offer-grid-empty">
      {serviceAddress.ElectricProviderList &&
      serviceAddress.ElectricProviderList.length ? (
        <>
          {serviceAddress.Address.State === 'TX' ? (
            <p>
              {serviceAddress.Address.Zip} is not in an area of Texas that
              participates in deregulated retail electric choice. <br />{' '}
              {includeAddressSearch ? (
                <Button onClick={() => setShowZipPopup(true)}>
                  Change Locations
                </Button>
              ) : null}
            </p>
          ) : (
            <>
              <Alert severity="warning">
                We don't have electric plan data for the zip code{' '}
                <strong style={{ whiteSpace: 'nowrap' }}>
                  {serviceAddress.Address.Zip} in {serviceAddress.Address.City},{' '}
                  {serviceAddress.Address.State}
                </strong>
                .
                <br />{' '}
                {includeAddressSearch ? (
                  <Button onClick={() => setShowZipPopup(true)}>
                    Change Locations
                  </Button>
                ) : null}
              </Alert>
              <p></p>
            </>
          )}

          <p>
            You'll need to talk to your electric provider directly about ways to
            save on your electric bill. The provider
            {serviceAddress.ElectricProviderList.length > 1 ? 's' : ''} below
            service
            {serviceAddress.ElectricProviderList.length > 1 ? '' : 's'} that
            location.
          </p>

          {serviceAddress.ElectricProviderList.map((provider) => (
            <>
              <ProviderLogo offer={{ ...provider }} />
              {provider.WebsiteUrl ? (
                <Button
                  variant="outlined"
                  href={provider.WebsiteUrl}
                  target="mi_offsite">
                  Visit {provider.DisplayName} <LaunchIcon />
                </Button>
              ) : null}
            </>
          ))}
        </>
      ) : (
        <>
          Sorry, there are currently no electric plans available for this area.
          <AddressSearch mode="zip" />
        </>
      )}

      {showZipPopup ? (
        <AddressSearchPopup
          mode="zip"
          hideSearchBy
          closeFunc={() => setShowAddressSearch(null)}
          title={'Change Location'}
          afterAddressSelected={() => {
            //do nothing, stay here
            setShowZipPopup(null);
          }}
        />
      ) : null}
    </div>
  ) : null;
}
function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoDeregulatedElectric);
