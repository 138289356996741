import React, { useEffect, useRef, useState } from 'react';
import Loadable from '@loadable/component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import WifiAnimation from '../WifiAnimation';
import { Alert, AlertTitle } from '@mui/material';

import * as dataApi from '../../api/dataApi';

import './index.css';

import UsageHistoryForm from './UsageHistoryForm';

const _chartOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
      endingShape: 'arrow'
    }
  },
  stroke: {
    width: [4, 0, 0]
  },
  xaxis: {
    categories: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  },
  markers: {
    size: 6,
    strokeWidth: 3,
    fillOpacity: 0,
    strokeOpacity: 0,
    hover: {
      size: 8
    }
  },
  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 100
  }
};

function UsageHistoryEntry({
  serviceAddress,
  siteConfig,
  showUsageHistoryPrompt,
  setShowUsageHistoryPrompt,
  usageHistory,
  setUsageHistory,
  monthList
}) {
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [chartOptions, setChartOptions] = useState(_chartOptions);
  const [chartData, setChartData] = useState(null);
  const usageEditTimeout = useRef();

  useEffect(() => {}, [serviceAddress]);

  useEffect(() => {
    usageEditTimeout.current = setTimeout(() => {
      let minUsage = null;
      let maxUsage = null;
      let _chartData = {
        name: 'Usage in kWh',
        type: 'line',
        data: []
      };

      monthList.map((month) => {
        if (!isNaN(usageHistory[month])) {
          if (usageHistory[month] < minUsage || minUsage === null) {
            minUsage = parseFloat(usageHistory[month]);
          }

          if (usageHistory[month] > maxUsage || maxUsage === null) {
            maxUsage = parseFloat(usageHistory[month]);
          }

          _chartData.data.push(parseFloat(usageHistory[month]));
        } else {
          _chartData.data.push(null);
        }
      });

      console.log(maxUsage);

      if (minUsage !== null && maxUsage !== null) {
        setChartOptions({
          ...chartOptions,
          yaxis: { ...chartOptions.yaxis, max: maxUsage + 1000 }
        });

        setChartData({ ..._chartData });
      }
    }, 500);
  }, [usageHistory]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (usageEditTimeout.current) {
        clearTimeout(usageEditTimeout.current);
      }
    };
  }, []);

  return showUsageHistoryPrompt ? (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="usage-history-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => setShowUsageHistoryPrompt(false)}>
          <CloseIcon />
        </IconButton>
        Your Usage History
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {usageHistory && usageHistory.SmartMeter ? (
          <Alert severity="success">
            <AlertTitle>Success!</AlertTitle>
            The usage history below was pulled directly from your smart meter.
          </Alert>
        ) : (
          <p className="directions">
            Enter your usage history in the form below to find the best plan for
            you based on your actual usage.
          </p>
        )}

        <UsageHistoryForm
          usageHistory={usageHistory}
          setUsageHistory={setUsageHistory}
        />

        {chartOptions && chartData ? (
          <div className="chart">
            <LoadableChart
              options={chartOptions}
              series={[chartData]}
              type="line"
            />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <>
          {saveError ? <div className="error">{saveError}</div> : null}
          <Button
            color="secondary"
            disabled={saving}
            variant="contained"
            onClick={() => {
              setShowUsageHistoryPrompt(false);
            }}>
            {saving ? 'Calculating...' : 'View Plans'}
          </Button>
          <Button
            onClick={() => setShowUsageHistoryPrompt(false)}
            variant="outlined">
            Close
          </Button>
        </>
      </DialogActions>
    </Dialog>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    texasREPList: state.commonData.texasREPList,
    siteConfig: state.commonData.siteConfig,
    monthList: state.commonData.monthList
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UsageHistoryEntry);

const LoadableChart = Loadable(() => import('react-apexcharts'));
