import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { compareElectric6Month } from '../../api/dataApi';
import { getFullCostDetail, round } from '../../api/offerCalc';

import './index.css';

function Electric6MonthAvgCompare({}) {
  const [offerList, setOfferList] = useState(null);
  const usage = 1000;

  useEffect(() => {
    compareElectric6Month(usage).then((_offerList) =>
      setOfferList(
        _offerList.map((offer) =>
          getFullCostDetail(offer, usage, {}, null, 'average')
        )
      )
    );
  }, []);

  return (
    <div
      className={
        'electric-6-month-avg-compare' +
        (offerList &&
        offerList.length === 2 &&
        offerList[0].PriceKWh > offerList[1].PriceKWh
          ? ' loaded'
          : '')
      }>
      <h3>Electric rates are down 20% or more depending on where you live.</h3>
      {offerList && offerList.length == 2 ? (
        <div className="comparison">
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Current
                    <br />
                    Lowest Rate
                  </TableCell>
                  <TableCell>
                    Same Plan
                    <br />6 Months Ago
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {round(offerList[1].PriceKWh, 1)}¢ per kWh
                  </TableCell>
                  <TableCell>{round(offerList[0].PriceKWh)}¢ per kWh</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    ${round(offerList[1].PriceDollars).toFixed(2)}/mo est. bill*
                  </TableCell>
                  <TableCell>
                    ${round(offerList[0].PriceDollars).toFixed(2)}/mo est. bill*
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="savings">
            <h5>Estimated monthly savings:</h5>
            <h4>
              $
              {round(
                offerList[0].PriceDollars - offerList[1].PriceDollars
              ).toFixed(2)}
              *
            </h4>
          </div>
        </div>
      ) : null}

      {offerList && offerList.length == 2 ? (
        <p>
          *Estimated prices above based on average usage of {usage} kWh and the
          lowest available rate.
        </p>
      ) : null}
      <h3>
        <strong>
          Enter your zip code below to find the lowest rate in your area.
        </strong>
      </h3>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Electric6MonthAvgCompare);
