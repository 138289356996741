import { ContactlessOutlined, ContactsOutlined } from '@mui/icons-material';
import React, { useEffect, useState, useRef } from 'react';

const providerList = [
  // {
  //   logoUrl:
  //     'https://mi-provider-logo.s3-us-west-1.amazonaws.com/74971_logo-provider-first-choice-power.png',
  //   providerName: 'First Choice Power'
  // },
  // {
  //   logoUrl:
  //     'https://mi-provider-logo.s3-us-west-1.amazonaws.com/75593_reliant-energy-logo-600x353.png',
  //   providerName: 'Reliant Energy'
  // },
  // {
  //   logoUrl:
  //     'https://mi-provider-logo.s3-us-west-1.amazonaws.com/75597_Direct-Energy-min.jpg',
  //   providerName: 'Direct Energy'
  // },
  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/75750_logo-provider-tara-energy.png',
    providerName: 'Tara Energy, Inc'
  },
  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/75841_txu.svg',
    providerName: 'TXU Energy'
  },
  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/76015_logo-provider-amigo-energy.png',
    providerName: 'Amigo Energy'
  },

  // {
  //   logoUrl:
  //     'https://mi-provider-logo.s3-us-west-1.amazonaws.com/76077_Discount-Power-Logo.jpg',
  //   providerName: 'Discount Power'
  // },
  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/76097_NextEra_Energy_logo_(1).svg',
    providerName: 'NextEra'
  },

  // {
  //   logoUrl:
  //     'https://mi-provider-logo.s3-us-west-1.amazonaws.com/76351_cirro.jpg',
  //   providerName: 'Cirro Corporation'
  // },

  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/77690_4Change-Energy-logo (1).jpg',
    providerName: '4Change Energy'
  },

  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85467_express-logo.svg',
    providerName: 'Express Energy'
  },

  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85468_logo-payless-color.svg',
    providerName: 'Payless Power'
  },

  {
    logoUrl:
      'https://mi-provider-logo.s3-us-west-1.amazonaws.com/77933_Pulse_Power_Logo.svg',
    providerName: 'Pulse Power'
  }
];

function TXProviderList({}) {
  const maxProvidersShown = 6;
  const [visibleProviderList, setVisibleProviderList] = useState(
    getRandom(providerList, maxProvidersShown)
  );
  const swapTimer = useRef();
  const classTimer = useRef();
  const [updatingProvider, setUpdatingProvider] = useState({});

  useEffect(() => {
    return () => {
      if (swapTimer.current) {
        clearTimeout(swapTimer.current);
      }

      if (classTimer.current) {
        clearTimeout(classTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (visibleProviderList) {
      swapTimer.current = setTimeout(() => {
        //Randomly select a slot to replace
        const randomSlotIndex = Math.round(
          getRandomArbitrary(0, maxProvidersShown)
        );

        //Create a list of providers not in use
        const _availableProviders = providerList.filter(
          (provider) =>
            visibleProviderList.filter(
              (visibleProvider) =>
                visibleProvider.providerName === provider.providerName
            ).length === 0
        );

        //Update the provider list
        const newProvider = { ...getRandom(_availableProviders, 1)[0] };
        setVisibleProviderList(
          visibleProviderList.map((provider, providerIndex) =>
            providerIndex === randomSlotIndex
              ? { ...newProvider }
              : { ...provider }
          )
        );

        setUpdatingProvider({
          ...updatingProvider,
          [newProvider.providerName]: true
        });

        classTimer.current = setTimeout(() => {
          removeUpdatingFlag();
        }, 200);
      }, 3000);
    }
  }, [visibleProviderList]);

  function removeUpdatingFlag() {
    let _updatingProvider = { ...updatingProvider };

    for (const key in _updatingProvider) {
      _updatingProvider[key] = false;
    }

    setUpdatingProvider({ ..._updatingProvider });
  }

  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  return visibleProviderList ? (
    <div className="provider-images">
      {visibleProviderList.map((provider) => (
        <div
          className={
            updatingProvider[provider.providerName] === true ? 'updating' : ''
          }>
          <img src={provider.logoUrl} alt={provider.providerName} />
        </div>
      ))}
    </div>
  ) : null;
}

export default TXProviderList;
