import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function SortIcon({ sortColumn, column }) {
  return sortColumn && sortColumn.indexOf(column) !== -1 ? (
    sortColumn.indexOf('-') === 0 ? (
      <ArrowDropDownIcon className="sort desc" />
    ) : (
      <ArrowDropDownIcon className="sort" />
    )
  ) : null;
}

export default SortIcon;
