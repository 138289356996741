import React from 'react';

import './index.css';

function WifiAnimation({}) {
  return (
    <div className="wifi-symbol-container">
      <div className="wifi-symbol">
        <div className="wifi-circle first"></div>
        <div className="wifi-circle second"></div>
        <div className="wifi-circle third"></div>
        <div className="wifi-circle fourth"></div>
      </div>
    </div>
  );
}

export default WifiAnimation;
