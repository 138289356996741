import React, { useState, useRef, useEffect } from 'react';
import Loadable from '@loadable/component';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../TXLandingPage/index.css';
import '../Default/index.css';

import { AppBar, TextField, Button } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import TXProviderList from '../TXLandingPage/TXProviderList';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as dataApi from '../../api/dataApi';
import * as offerCalc from '../../api/offerCalc';

import ThousandKWhTrap from './ThousandKWhTrap';
import Rollercoaster from './Rollercoaster';
import DontBeAverage from './DontBeAverage';
import SCLandingWrapper from '../SCLandingWrapper';

const $ = typeof window !== 'undefined' ? window.$ : {};

const offer = {
  ProviderName: '4Change Energy',
  OfferID: 1636480,
  ProviderID: 77690,
  OfferName: 'Maxx Saver Select 12',
  PriceDollars: null,
  TermLength: 12,
  OfferDetailText: '',
  InternetDownloadSpeedMb: null,
  TVChannelCount: null,
  PhoneText: null,
  ProviderServiceList: 'Electric',
  HomeSecurityText: null,
  FeatureBadge1: '',
  LogoFile: '77690_4change_energy_logoV2-01.svg',
  PUCNumber: '10041',
  ProviderStatusID: 1,
  ProductKey: '4Change Energy-Oncor-Maxx Saver Select 12',
  OfferStatusID: 2,
  OfferStatusName: 'Active',
  ProviderOfferCode1: '1319161',
  ProviderOfferCode2: '',
  ProviderOfferCode3: '',
  ProviderOfferCode4: '',
  ProviderOfferCode5: '',
  ProviderOfferCode6: '',
  ProviderOfferCode7: '',
  ProviderOfferCode8: '',
  ProviderOfferCode9: '',
  OfferValidStartDate: '2021-07-21T00:00:00',
  OfferValidEndDate: null,
  TerminationFeeTypeID: 2,
  TerminationFeeTypeName: 'PMR',
  TerminationFeeAmount: 20,
  IsPrepaid: false,
  NewCustomersOnly: false,
  ExistingCustomersOnly: false,
  EBillOnly: false,
  FeatureBadge2: '',
  FeatureBadge3: '',
  FeatureBadge4: '',
  FeatureBadge5: '',
  OffsiteSignUpUrl: 'https://www.4changeenergy.com/',
  UtilityID: 5,
  EFLUrl:
    'https://www.4changeenergy.com/viewpdf.aspx/Docs/efl_mxsela12lbt_o.pdf',
  TOSUrl: 'https://www.4changeenergy.com/Docs/TOSA_4ChangeEnergy.pdf',
  YRAACUrl: 'https://www.4changeenergy.com/Docs/YRAC_4ChangeEnergy.pdf',
  PriceAt500: 0.209,
  PriceAt1000: 0.105,
  PriceAt2000: 0.153,
  UtilityBaseCharge: 4.23,
  UtilityUsageCharge: 0.051893,
  ProviderBaseCharge_Tier1_Amount: 0,
  ProviderBaseCharge_Tier1_Start: null,
  ProviderBaseCharge_Tier1_End: null,
  ProviderBaseCharge_Tier2_Amount: null,
  ProviderBaseCharge_Tier2_Start: null,
  ProviderBaseCharge_Tier2_End: null,
  ProviderBaseCharge_Tier3_Amount: null,
  ProviderBaseCharge_Tier3_Start: null,
  ProviderBaseCharge_Tier3_End: null,
  ProviderBaseCharge_Tier4_Amount: null,
  ProviderBaseCharge_Tier4_Start: null,
  ProviderBaseCharge_Tier4_End: null,
  ProviderBaseCharge_Tier5_Amount: null,
  ProviderBaseCharge_Tier5_Start: null,
  ProviderBaseCharge_Tier5_End: null,
  ProviderBaseCharge_PerDay_Amount: null,
  ProviderBaseCharge_PerDay_UsageCredit: null,
  ProviderUsageCharge_Tier1_Amount: 0.148967,
  ProviderUsageCharge_Tier1_Start: 0,
  ProviderUsageCharge_Tier1_End: null,
  ProviderUsageCharge_Tier2_Amount: null,
  ProviderUsageCharge_Tier2_Start: null,
  ProviderUsageCharge_Tier2_End: null,
  ProviderUsageCharge_Tier3_Amount: null,
  ProviderUsageCharge_Tier3_Start: null,
  ProviderUsageCharge_Tier3_End: null,
  ProviderUsageCharge_Tier4_Amount: null,
  ProviderUsageCharge_Tier4_Start: null,
  ProviderUsageCharge_Tier4_End: null,
  ProviderUsageCharge_Tier5_Amount: null,
  ProviderUsageCharge_Tier5_Start: null,
  ProviderUsageCharge_Tier5_End: null,
  ProviderFlatCharge_Tier1_Amount: null,
  ProviderFlatCharge_Tier1_Start: null,
  ProviderFlatCharge_Tier1_End: null,
  ProviderFlatCharge_Tier2_Amount: null,
  ProviderFlatCharge_Tier2_Start: null,
  ProviderFlatCharge_Tier2_End: null,
  ProviderFlatCharge_Tier3_Amount: null,
  ProviderFlatCharge_Tier3_Start: null,
  ProviderFlatCharge_Tier3_End: null,
  ProviderFlatCharge_Tier4_Amount: null,
  ProviderFlatCharge_Tier4_Start: null,
  ProviderFlatCharge_Tier4_End: null,
  ProviderFlatCharge_Tier5_Amount: null,
  ProviderFlatCharge_Tier5_Start: null,
  ProviderFlatCharge_Tier5_End: null,
  ProviderCredit_Tier1_Amount: 100,
  ProviderCredit_Tier1_Start: 1000,
  ProviderCredit_Tier1_End: null,
  ProviderCredit_Tier2_Amount: 0,
  ProviderCredit_Tier2_Start: 0,
  ProviderCredit_Tier2_End: 0,
  ProviderCredit_Tier3_Amount: 0,
  ProviderCredit_Tier3_Start: 0,
  ProviderCredit_Tier3_End: 0,
  ProviderCredit_Tier4_Amount: null,
  ProviderCredit_Tier4_Start: null,
  ProviderCredit_Tier4_End: null,
  ProviderCredit_Tier5_Amount: null,
  ProviderCredit_Tier5_Start: null,
  ProviderCredit_Tier5_End: null,
  BaseChargeAdjustment_All: null,
  BaseChargeAdjustment_Provider: null,
  BaseChargeAdjustment_Utility: null,
  UsageChargeAdjustment_All: null,
  UsageChargeAdjustment_Provider: null,
  UsageChargeAdjustment_Utility: null,
  EBillProviderDiscount_All: null,
  EBillProviderDiscount_ProviderBaseCharge: null,
  EBillProviderDiscount_ProviderUsageCharge: null,
  UtilityName: 'Oncor',
  RenewableContent: 6,
  ProductTypeID: 6,
  CompareStateAverage_Start: 1000,
  CompareStateAverage_End: 1760,
  OrderPhone: '833-564-1391',
  CompareStateAverage_Count: 1,
  DisplayName: 'Maxx Saver Select 12',
  OfferTypeID: 1,
  OfferTypeName: 'Plan',
  AutoPayRequired: false,
  AutoPayDiscount: null,
  AutoPayDiscountTerm: null,
  TermTypeID: null,
  TermTypeName: null,
  Term_Rate1_Amount: null,
  Term_Rate1_Start: null,
  Term_Rate1_End: null,
  Term_Rate2_Amount: null,
  Term_Rate2_Start: null,
  Term_Rate2_End: null,
  Term_Rate3_Amount: null,
  Term_Rate3_Start: null,
  Term_Rate3_End: null,
  Term_Rate4_Amount: null,
  Term_Rate4_Start: null,
  Term_Rate4_End: null,
  Term_Rate5_Amount: null,
  Term_Rate5_Start: null,
  Term_Rate5_End: null,
  TermDiscount: null,
  Bundle1_OfferID: null,
  Bundle2_OfferID: null,
  Bundle3_OfferID: null,
  Bundle4_OfferID: null,
  Bundle5_OfferID: null,
  PhoneIsUnlimitedCalling: null,
  InternetRequired: null,
  TVRequired: null,
  PhoneRequired: null,
  HomeSecurityRequired: null,
  IsFiber: null,
  DataMaxGb: null,
  DataOverageFee: null,
  MinimumUpfrontPayment: null,
  Charge1_Group: null,
  Charge1_Name: null,
  Charge1_MonthlyAmount: null,
  Charge1_PurchaseAmount: null,
  Charge1_Term: null,
  Charge1_Term_Start: null,
  Charge1_Term_End: null,
  Charge1_IsRequired: null,
  Charge2_Group: null,
  Charge2_Name: null,
  Charge2_MonthlyAmount: null,
  Charge2_PurchaseAmount: null,
  Charge2_Term: null,
  Charge2_Term_Start: null,
  Charge2_Term_End: null,
  Charge2_IsRequired: null,
  Charge3_Group: null,
  Charge3_Name: null,
  Charge3_MonthlyAmount: null,
  Charge3_PurchaseAmount: null,
  Charge3_Term: null,
  Charge3_Term_Start: null,
  Charge3_Term_End: null,
  Charge3_IsRequired: null,
  Charge4_Group: null,
  Charge4_Name: null,
  Charge4_MonthlyAmount: null,
  Charge4_PurchaseAmount: null,
  Charge4_Term: null,
  Charge4_Term_Start: null,
  Charge4_Term_End: null,
  Charge4_IsRequired: null,
  Charge5_Group: null,
  Charge5_Name: null,
  Charge5_MonthlyAmount: null,
  Charge5_PurchaseAmount: null,
  Charge5_Term: null,
  Charge5_Term_Start: null,
  Charge5_Term_End: null,
  Charge5_IsRequired: null,
  Charge6_Group: null,
  Charge6_Name: null,
  Charge6_MonthlyAmount: null,
  Charge6_PurchaseAmount: null,
  Charge6_Term: null,
  Charge6_Term_Start: null,
  Charge6_Term_End: null,
  Charge6_IsRequired: null,
  Charge7_Group: null,
  Charge7_Name: null,
  Charge7_MonthlyAmount: null,
  Charge7_PurchaseAmount: null,
  Charge7_Term: null,
  Charge7_Term_Start: null,
  Charge7_Term_End: null,
  Charge7_IsRequired: null,
  Charge8_Group: null,
  Charge8_Name: null,
  Charge8_MonthlyAmount: null,
  Charge8_PurchaseAmount: null,
  Charge8_Term: null,
  Charge8_Term_Start: null,
  Charge8_Term_End: null,
  Charge8_IsRequired: null,
  Charge9_Group: null,
  Charge9_Name: null,
  Charge9_MonthlyAmount: null,
  Charge9_PurchaseAmount: null,
  Charge9_Term: null,
  Charge9_Term_Start: null,
  Charge9_Term_End: null,
  Charge9_IsRequired: null,
  Charge10_Group: null,
  Charge10_Name: null,
  Charge10_MonthlyAmount: null,
  Charge10_PurchaseAmount: null,
  Charge10_Term: null,
  Charge10_Term_Start: null,
  Charge10_Term_End: null,
  Charge10_IsRequired: null,
  Charge11_Group: null,
  Charge11_Name: null,
  Charge11_MonthlyAmount: null,
  Charge11_PurchaseAmount: null,
  Charge11_Term: null,
  Charge11_Term_Start: null,
  Charge11_Term_End: null,
  Charge11_IsRequired: null,
  Charge12_Group: null,
  Charge12_Name: null,
  Charge12_MonthlyAmount: null,
  Charge12_PurchaseAmount: null,
  Charge12_Term: null,
  Charge12_Term_Start: null,
  Charge12_Term_End: null,
  Charge12_IsRequired: null,
  Charge13_Group: null,
  Charge13_Name: null,
  Charge13_MonthlyAmount: null,
  Charge13_PurchaseAmount: null,
  Charge13_Term: null,
  Charge13_Term_Start: null,
  Charge13_Term_End: null,
  Charge13_IsRequired: null,
  Charge14_Group: null,
  Charge14_Name: null,
  Charge14_MonthlyAmount: null,
  Charge14_PurchaseAmount: null,
  Charge14_Term: null,
  Charge14_Term_Start: null,
  Charge14_Term_End: null,
  Charge14_IsRequired: null,
  Charge15_Group: null,
  Charge15_Name: null,
  Charge15_MonthlyAmount: null,
  Charge15_PurchaseAmount: null,
  Charge15_Term: null,
  Charge15_Term_Start: null,
  Charge15_Term_End: null,
  Charge15_IsRequired: null,
  Charge16_Group: null,
  Charge16_Name: null,
  Charge16_MonthlyAmount: null,
  Charge16_PurchaseAmount: null,
  Charge16_Term: null,
  Charge16_Term_Start: null,
  Charge16_Term_End: null,
  Charge16_IsRequired: null,
  Charge17_Group: null,
  Charge17_Name: null,
  Charge17_MonthlyAmount: null,
  Charge17_PurchaseAmount: null,
  Charge17_Term: null,
  Charge17_Term_Start: null,
  Charge17_Term_End: null,
  Charge17_IsRequired: null,
  Charge18_Group: null,
  Charge18_Name: null,
  Charge18_MonthlyAmount: null,
  Charge18_PurchaseAmount: null,
  Charge18_Term: null,
  Charge18_Term_Start: null,
  Charge18_Term_End: null,
  Charge18_IsRequired: null,
  Charge19_Group: null,
  Charge19_Name: null,
  Charge19_MonthlyAmount: null,
  Charge19_PurchaseAmount: null,
  Charge19_Term: null,
  Charge19_Term_Start: null,
  Charge19_Term_End: null,
  Charge19_IsRequired: null,
  Charge20_Group: null,
  Charge20_Name: null,
  Charge20_MonthlyAmount: null,
  Charge20_PurchaseAmount: null,
  Charge20_Term: null,
  Charge20_Term_Start: null,
  Charge20_Term_End: null,
  Charge20_IsRequired: null,
  Charge1_MaxCount: null,
  Charge2_MaxCount: null,
  Charge3_MaxCount: null,
  Charge4_MaxCount: null,
  Charge5_MaxCount: null,
  Charge6_MaxCount: null,
  Charge7_MaxCount: null,
  Charge8_MaxCount: null,
  Charge9_MaxCount: null,
  Charge10_MaxCount: null,
  Charge11_MaxCount: null,
  Charge12_MaxCount: null,
  Charge13_MaxCount: null,
  Charge14_MaxCount: null,
  Charge15_MaxCount: null,
  Charge16_MaxCount: null,
  Charge17_MaxCount: null,
  Charge18_MaxCount: null,
  Charge19_MaxCount: null,
  Charge20_MaxCount: null,
  Charge1_IncludedInPrice: null,
  Charge2_IncludedInPrice: null,
  Charge3_IncludedInPrice: null,
  Charge4_IncludedInPrice: null,
  Charge5_IncludedInPrice: null,
  Charge6_IncludedInPrice: null,
  Charge7_IncludedInPrice: null,
  Charge8_IncludedInPrice: null,
  Charge9_IncludedInPrice: null,
  Charge10_IncludedInPrice: null,
  Charge11_IncludedInPrice: null,
  Charge12_IncludedInPrice: null,
  Charge13_IncludedInPrice: null,
  Charge14_IncludedInPrice: null,
  Charge15_IncludedInPrice: null,
  Charge16_IncludedInPrice: null,
  Charge17_IncludedInPrice: null,
  Charge18_IncludedInPrice: null,
  Charge19_IncludedInPrice: null,
  Charge20_IncludedInPrice: null,
  Bundle6_OfferID: null,
  Bundle7_OfferID: null,
  Bundle8_OfferID: null,
  Bundle9_OfferID: null,
  Bundle10_OfferID: null,
  Charge21_Group: null,
  Charge21_Name: null,
  Charge21_MonthlyAmount: null,
  Charge21_PurchaseAmount: null,
  Charge21_Term: null,
  Charge21_Term_Start: null,
  Charge21_Term_End: null,
  Charge21_IsRequired: null,
  Charge21_MaxCount: null,
  Charge21_IncludedInPrice: null,
  Charge22_Group: null,
  Charge22_Name: null,
  Charge22_MonthlyAmount: null,
  Charge22_PurchaseAmount: null,
  Charge22_Term: null,
  Charge22_Term_Start: null,
  Charge22_Term_End: null,
  Charge22_IsRequired: null,
  Charge22_MaxCount: null,
  Charge22_IncludedInPrice: null,
  Charge23_Group: null,
  Charge23_Name: null,
  Charge23_MonthlyAmount: null,
  Charge23_PurchaseAmount: null,
  Charge23_Term: null,
  Charge23_Term_Start: null,
  Charge23_Term_End: null,
  Charge23_IsRequired: null,
  Charge23_MaxCount: null,
  Charge23_IncludedInPrice: null,
  Charge24_Group: null,
  Charge24_Name: null,
  Charge24_MonthlyAmount: null,
  Charge24_PurchaseAmount: null,
  Charge24_Term: null,
  Charge24_Term_Start: null,
  Charge24_Term_End: null,
  Charge24_IsRequired: null,
  Charge24_MaxCount: null,
  Charge24_IncludedInPrice: null,
  Charge25_Group: null,
  Charge25_Name: null,
  Charge25_MonthlyAmount: null,
  Charge25_PurchaseAmount: null,
  Charge25_Term: null,
  Charge25_Term_Start: null,
  Charge25_Term_End: null,
  Charge25_IsRequired: null,
  Charge25_MaxCount: null,
  Charge25_IncludedInPrice: null,
  Charge26_Group: null,
  Charge26_Name: null,
  Charge26_MonthlyAmount: null,
  Charge26_PurchaseAmount: null,
  Charge26_Term: null,
  Charge26_Term_Start: null,
  Charge26_Term_End: null,
  Charge26_IsRequired: null,
  Charge26_MaxCount: null,
  Charge26_IncludedInPrice: null,
  Charge27_Group: null,
  Charge27_Name: null,
  Charge27_MonthlyAmount: null,
  Charge27_PurchaseAmount: null,
  Charge27_Term: null,
  Charge27_Term_Start: null,
  Charge27_Term_End: null,
  Charge27_IsRequired: null,
  Charge27_MaxCount: null,
  Charge27_IncludedInPrice: null,
  Charge28_Group: null,
  Charge28_Name: null,
  Charge28_MonthlyAmount: null,
  Charge28_PurchaseAmount: null,
  Charge28_Term: null,
  Charge28_Term_Start: null,
  Charge28_Term_End: null,
  Charge28_IsRequired: null,
  Charge28_MaxCount: null,
  Charge28_IncludedInPrice: null,
  Charge29_Group: null,
  Charge29_Name: null,
  Charge29_MonthlyAmount: null,
  Charge29_PurchaseAmount: null,
  Charge29_Term: null,
  Charge29_Term_Start: null,
  Charge29_Term_End: null,
  Charge29_IsRequired: null,
  Charge29_MaxCount: null,
  Charge29_IncludedInPrice: null,
  Charge30_Group: null,
  Charge30_Name: null,
  Charge30_MonthlyAmount: null,
  Charge30_PurchaseAmount: null,
  Charge30_Term: null,
  Charge30_Term_Start: null,
  Charge30_Term_End: null,
  Charge30_IsRequired: null,
  Charge30_MaxCount: null,
  Charge30_IncludedInPrice: null,
  FeaturesLabel: null,
  FeaturesText: null,
  DisclosuresLabel: null,
  DisclosuresText: null,
  SummaryText: null,
  ApiID: 601,
  LocalitySourceProviderID: null,
  ChannelsLabel: null,
  ChannelsText: null,
  ChannelsLinkLabel: null,
  ChannelsLinkUrl: null,
  PriceContract: null,
  CSPhone: '855-784-2426',
  BundleOptionID: 1,
  LogoFileEmail: '77690_Email_4change_energy_logo.png',
  OrderPriority: 3,
  SeparateOrderForEachOffer: false,
  ContactInfoFooter:
    '<p style="font-weight: 400; text-align: center;">Value Based Brands LLC d/b/a 4Change Energy, All rights reserved REP #10041<br /><a href="http://www.4changeenergy.com/" data-saferedirecturl="https://www.google.com/url?q=http://www.4ChangeEnergy.com&amp;source=gmail&amp;ust=1657649867576000&amp;usg=AOvVaw0GG-H8YouWvZzU6FSd_CLn">www.4ChangeEnergy.com</a> | Service@4ChangeEnergy.com | (833) 564-1391<br />4Change Energy PO Box 660361 Dallas, TX 75266-0361</p>',
  ApiName: 'Maxx Saver Select 12',
  AdvertisedPrice: 0,
  SatisfactionGuaranteeText: null
};

const _seasonalUsage = {
  July: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'July',
    Usage: 1466.79,
    UsageVariable: 1.303026,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  June: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'June',
    Usage: 1221.22,
    UsageVariable: 1.04851,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  May: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'May',
    Usage: 897.24,
    UsageVariable: 0.762279,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  April: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'April',
    Usage: 790.4,
    UsageVariable: 0.677205,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  March: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'March',
    Usage: 1035.21,
    UsageVariable: 0.901767,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  February: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'February',
    Usage: 1049.47,
    UsageVariable: 0.926774,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  January: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'January',
    Usage: 1160.58,
    UsageVariable: 1.033666,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  December: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'December',
    Usage: 1013.54,
    UsageVariable: 0.907101,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  November: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'November',
    Usage: 834.96,
    UsageVariable: 0.752155,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  October: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'October',
    Usage: 1023.84,
    UsageVariable: 0.931475,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  September: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'September',
    Usage: 1381.75,
    UsageVariable: 1.250419,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  },
  August: {
    SourceGroup: 'State',
    UsageHistorySourceName: 'Statewide Average for TX',
    Month: 'August',
    Usage: 1633.13,
    UsageVariable: 1.453726,
    AveragePricePerCustomer_12Month: 11.92,
    AverageUsagePerCustomer_12Month: 1125.68,
    LastUpdated: '02/28/2023'
  }
};

const state12MonthAvg_PerKWh =
  _seasonalUsage.January.AveragePricePerCustomer_12Month;
const state12MonthAvg_PerKWh_Rounded = offerCalc.round(
  _seasonalUsage.January.AveragePricePerCustomer_12Month,
  0
);
const state12MonthAvg_Usage =
  _seasonalUsage.January.AverageUsagePerCustomer_12Month;
export const stateUsageLastUpdated = _seasonalUsage.January.LastUpdated;

export let _offerChartData = [];

for (let i = 25; i <= 2400; i += 25) {
  const costDetail = offerCalc.getFullCostDetail(
    offer,
    i,
    {
      ElectricUsageHistory: { State: _seasonalUsage }
    },
    {},
    'estimated',
    null
  );

  let lowUsage = null;
  let highUsage = null;
  let lowDollars = null;
  let highDollars = null;
  let lowPrice = null;
  let highPrice = null;
  let creditCount = 0;

  Object.keys(costDetail.trueCost.estimated.table).map((month) => {
    const calcMonth = costDetail.trueCost.estimated.table[month];
    const usage = calcMonth.usage;
    const dollars = calcMonth.dollars;
    const price = calcMonth.perKWh;

    if (lowUsage === null || usage < lowUsage) {
      lowUsage = usage;
    }

    if (highUsage === null || usage > highUsage) {
      highUsage = usage;
    }

    if (lowDollars === null || dollars < lowDollars) {
      lowDollars = dollars;
    }

    if (highDollars === null || dollars > highDollars) {
      highDollars = dollars;
    }

    if (lowPrice === null || price < lowPrice) {
      lowPrice = price;
    }

    if (highPrice === null || price > highPrice) {
      highPrice = price;
    }

    if (calcMonth.creditApplies) {
      creditCount++;
    }
  });

  _offerChartData.push({
    usage: i,
    PriceAt1000: offer.PriceAt1000,
    perKWh: offerCalc.round(costDetail.SingleMonthPriceKWh, 1),
    trueCost_Dollars: offerCalc.round(costDetail.TrueCost_Dollars, 2),
    trueCost_PerKWh: offerCalc.round(costDetail.TrueCost_PerKWh, 1),
    trueCost_Usage: costDetail.TrueCost_Usage,
    lowUsage,
    highUsage,
    lowDollars,
    highDollars,
    lowPrice,
    highPrice,
    creditCount
  });
}

export const _dontBeAverageChartOptions = {
  chart: {
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },

  stroke: {
    width: [2, 3],
    curve: 'smooth',
    dashArray: [5, 0]
  },
  colors: ['#ff3366', '#337eff'],

  xaxis: {
    categories: _offerChartData.map((_price) => _price.usage),
    tickAmount: 24,
    type: 'numeric',
    min: 0,
    max: 2400,
    labels: {
      formatter: (value) => {
        return offerCalc.round(value, 1).toFixed(0) + ' kWh';
      }
    }
  },

  yaxis: {
    tickAmount: 9,
    min: 0,
    max: 35,

    labels: {
      formatter: (value) => {
        return offerCalc.round(value, 1).toFixed(1) + '¢';
      },
      show: true
    }
  }
};

export const _offerChartSeries = [
  {
    name: 'Avertised Price',
    data: _offerChartData.map((_price) =>
      (_price.PriceAt1000 * 100).toFixed(1)
    ),
    type: 'line'
  },
  {
    name: 'Price per kWh',
    data: _offerChartData.map((_price) => _price.perKWh),
    type: 'line'
  }
];

const _monthsOfYear = dataApi.getMonthsOfYear();

const _seasonalCurveChartSeries = {
  name: 'Usage',
  data: _monthsOfYear
    .map((month) => _seasonalUsage[month].Usage)
    .concat([_seasonalUsage['January'].Usage]),
  type: 'line'
};

const _seasonalCurveChartOptions = {
  chart: {
    height: '1000px',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    animations: {
      enabled: true,

      //      speed: 2000
      // animateGradually: {
      //   enabled: true,
      //   delay: 150
      // },
      dynamicAnimation: {
        enabled: true,
        speed: 5000
      }
    }
  },

  stroke: {
    width: [10, 0],
    curve: 'smooth',
    dashArray: 5
  },
  // colors: ['#337eff'],

  xaxis: {
    categories: _monthsOfYear.map((month) => month),

    labels: {
      show: true
      // formatter: value => {
      //   return offerCalc.round(value, 1).toFixed(0) + ' kWh';
      // }
    }
  },

  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 2000,
    labels: {
      show: true,
      formatter: (value) => {
        return offerCalc.round(value, 1).toFixed(0) + ' kWh';
      }
    }
  }
};

function LandingSmarterChoice({
  actions,
  serviceAddress,
  siteConfig,
  webSocket
}) {
  const zipFromCookie = dataApi.getCookie('sc-zip-code');
  const _city = useQueryParam('city', null);

  const [zipCode, setZipCode] = useState(zipFromCookie ? zipFromCookie : '');

  const [city, setCity] = useState(_city);

  console.log('zipFromCookie', zipFromCookie);

  const [billAmount, setBillAmount] = useState(
    dataApi.getCookie('sc-last-bill')
  );

  const [billUsage, setBillUsage] = useState(
    dataApi.getCookie('sc-last-usage')
  );

  useEffect(() => {
    setCity(_city);
  }, [_city]);

  function tabOnEnter(event, nextField) {
    if (event.keyCode === 13) {
      if (zipCode !== null && billAmount !== null && billUsage !== null) {
        submitForm();
      } else if (document.getElementById(nextField)) {
        document.getElementById(nextField).focus();
      }
    }
  }

  function submitForm() {
    console.log('zipCode.length', zipCode.length);
    if (zipCode && String(zipCode).length === 5) {
      dataApi.setCookie('sc-last-bill', billAmount);
      dataApi.setCookie('sc-last-usage', billUsage);
      dataApi.setCookie('sc-zip-code', zipCode);
      dataApi.setCookie('sc-smart-meter-prompted', '1', 24);

      dataApi.setCookie(
        'sc-usage-setting',
        billAmount && billUsage ? 'lastBill' : 'estimated'
      );

      if (
        !serviceAddress ||
        !serviceAddress.Address ||
        serviceAddress.Address.Zip !== zipCode
      ) {
        actions.pageLoading(true, 'Searching plans...');

        dataApi
          .saveServiceAddress('', '', '', '', zipCode, 6)
          .then((result) => {
            if (result && result.Address) {
              actions.serviceAddressUpdated(result);
            }

            navigate('/electric-rates/');
          });
      } else {
        navigate('/electric-rates/');
      }
    } else {
      document.getElementById('zipCode').focus();
    }
  }

  return <SCLandingWrapper city={city} />;

  // return city ? (
  //   <SCLandingWrapper city={city} />
  // ) : (
  //   <div className="sc-landing">
  //     <div className="rotated-box-container">
  //       <div className="rotated-box"></div>
  //     </div>
  //     <div className="main hero">
  //       <h2>
  //         The average Texan overpays by about 15% for electricity…
  //         <br />
  //         <strong>Don’t be average!</strong>
  //       </h2>
  //     </div>
  //     <div className="main address-lookup-header">
  //       <div>
  //         <h1>
  //           Find YOUR lowest electric rate
  //           {/* with{' '}
  //           <img src={withPrefix(siteConfig.logoWhite)} /> */}
  //         </h1>
  //       </div>
  //     </div>

  //     <AppBar
  //       position="sticky"
  //       className={
  //         'address-lookup' +
  //         (serviceAddress && serviceAddress.Address ? ' with-address' : '')
  //       }>
  //       <div>
  //         <div className="form-field">
  //           {/* <p className="desktop">View electric plans in your area</p>
  //           <p className="mobile">
  //             Enter your zip code to view electric plans in your area
  //           </p> */}
  //           <TextField
  //             variant="filled"
  //             id="zipCode"
  //             value={zipCode}
  //             label={
  //               <>
  //                 Enter <span className="hide-mobile">your </span>zip code
  //               </>
  //             }
  //             onChange={(event) => {
  //               if (event.target.value) {
  //                 setZipCode(parseInt(event.target.value.substring(0, 5)));

  //                 if (
  //                   event.target.value.length >= 5 &&
  //                   document.getElementById('lastBillAmount')
  //                 ) {
  //                   document.getElementById('lastBillAmount').focus();
  //                 }
  //               } else {
  //                 setZipCode(null);
  //               }
  //             }}
  //             onKeyDown={(event) => {
  //               //tabOnEnter(event, 'lastBillAmount');
  //               if (event.keyCode === 13) {
  //                 console.log('sbimt');
  //                 submitForm();
  //               }
  //             }}
  //             onFocus={(event) => event.target.select()}
  //             type="number"
  //             inputProps={{ inputMode: 'numeric', maxLength: 5 }}
  //           />
  //           {/* <span className="math">+</span> */}
  //         </div>
  //         {/* <div className="form-field middle">
  //           <p>
  //             For more personalized results add your last bill amount and usage
  //           </p>
  //           <TextField
  //             value={billAmount}
  //             id="lastBillAmount"
  //             variant="filled"
  //             label="Last bill amount (optional)"
  //             placeholder="$"
  //             onChange={(event) => {
  //               if (event.target.value) {
  //                 setBillAmount(parseFloat(event.target.value));
  //               } else {
  //                 setBillAmount(null);
  //               }
  //             }}
  //             onKeyDown={(event) => {
  //               tabOnEnter(event, 'lastBillUsage');
  //             }}
  //             onFocus={(event) => event.target.select()}
  //             type="number"
  //             inputProps={{ inputMode: 'numeric' }}
  //           />
  //           <TextField
  //             value={billUsage}
  //             id="lastBillUsage"
  //             variant="filled"
  //             label="Last bill usage (optional)"
  //             placeholder="kWh"
  //             onChange={(event) => {
  //               if (event.target.value) {
  //                 setBillUsage(parseInt(event.target.value));
  //               } else {
  //                 setBillUsage(null);
  //               }
  //             }}
  //             onKeyDown={(event) => {
  //               tabOnEnter(event, 'zipCode');
  //             }}
  //             onFocus={(event) => event.target.select()}
  //             type="number"
  //             inputProps={{ inputMode: 'numeric' }}
  //           />
  //         </div> */}
  //         <div className="form-field right">
  //           {/* <span className="math">=</span> */}
  //           <Button
  //             variant="contained"
  //             color="secondary"
  //             className={!zipCode || String(zipCode).length < 5 ? 'no-zip' : ''}
  //             onClick={() => submitForm()}
  //             disabled={!webSocket.connected}
  //             data-buttonid={'find-your-lowest-rate'}>
  //             Compare Rates <ChevronRightIcon />
  //           </Button>
  //         </div>
  //       </div>
  //     </AppBar>

  //     <div className="main jump">
  //       <ul>
  //         <li>
  //           <div>
  //             <h3>The 1,000-kWh Trap</h3>
  //             <p>
  //               The plans with the lowest advertised prices are not always the
  //               best overall deals.
  //             </p>
  //             <Button
  //               className="link"
  //               onClick={() => {
  //                 document
  //                   .getElementById('article1')
  //                   .scrollIntoView({ behavior: 'smooth', block: 'start' });
  //               }}
  //               data-buttonid={'article-jump_1000-kwh-trap'}>
  //               Read more <br />
  //               <ArrowDownward />
  //             </Button>
  //           </div>
  //         </li>
  //         <li>
  //           <div>
  //             <h3>Remember the Rollercoaster</h3>
  //             <p>
  //               Why accounting for seasonal usage fluctuations is critical to
  //               finding your best price.
  //             </p>
  //             <Button
  //               className="link"
  //               onClick={() => {
  //                 document
  //                   .getElementById('article2')
  //                   .scrollIntoView({ behavior: 'smooth', block: 'start' });
  //               }}
  //               data-buttonid={'article-jump_remember-the-rollercoaster'}>
  //               Read more <br />
  //               <ArrowDownward />
  //             </Button>
  //           </div>
  //         </li>
  //         <li>
  //           <div>
  //             <h3>Don't Be Average</h3>
  //             <p>
  //               We may not be able to reduce what you use, but we can reduce
  //               what you pay.
  //             </p>
  //             <Button
  //               className="link"
  //               onClick={() => {
  //                 document
  //                   .getElementById('article3')
  //                   .scrollIntoView({ behavior: 'smooth', block: 'start' });
  //               }}
  //               data-buttonid={'article-jump_dont-be-average'}>
  //               Read more <br />
  //               <ArrowDownward />
  //             </Button>
  //           </div>
  //         </li>
  //       </ul>
  //     </div>

  //     <TXProviderList />

  //     <ThousandKWhTrap />

  //     <Rollercoaster />

  //     <div className="rotated-box-container">
  //       <div className="rotated-box second"></div>
  //     </div>

  //     <DontBeAverage />
  //   </div>
  // );
}
function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingSmarterChoice);

const LoadableChart = Loadable(() => import('react-apexcharts'));
