import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AddressSearch from './index';

import './index.css';

function AddressSearchPopup(props) {
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [addressSearched, setAddressSearched] = useState(false);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className={
        'address-search-popup' +
        (addressSearched ? ' address-searched' : '') +
        (props.hideSearchBy ? ' no-search-by' : '')
      }>
      <DialogTitle id="form-dialog-title">
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {props.title
          ? props.title
          : props.mode === 'zip'
          ? 'Compare Rates'
          : 'Address Search'}
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            if (props.closeFunc) {
              props.closeFunc();
            }
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent width="100%">
        <AddressSearch {...props} />
      </DialogContent>
    </Dialog>
  );
}

export default AddressSearchPopup;
