import React, { isValidElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import * as dataApi from '../../api/dataApi';

function IntroText({
  serviceAddress,
  siteConfig,
  trackAs,
  text,
  mobileText,
  onVisibleChange
}) {
  const cookie = trackAs ? 'sc-intro-' + trackAs : null;
  const [visible, setVisible] = useState(false);

  const [interpolatedValues, setInterpolatedValues] = useState();

  useEffect(() => {
    if (cookie) {
      const cookieValue = dataApi.getCookie(cookie);
      setVisible(cookieValue != '1');
    }
  }, []);

  useEffect(() => {
    if (serviceAddress) {
      setInterpolatedValues({
        text: interpolate(text),
        mobileText: interpolate(mobileText)
      });
    }
  }, [text, mobileText, serviceAddress]);

  useEffect(() => {
    if (onVisibleChange) {
      onVisibleChange(visible);
    }
  }, [visible]);

  function interpolate(value) {
    return value
      ? value
          .split('{propertyName}')
          .join(
            serviceAddress.Address && serviceAddress.Address.PropertyName
              ? serviceAddress.Address.PropertyName
              : 'your new community'
          )
          .split('{propertyNameAllCaps}')
          .join(
            serviceAddress.Address && serviceAddress.Address.PropertyName
              ? serviceAddress.Address.PropertyName.toUpperCase()
              : 'YOUR NEW COMMUNITY'
          )
          .split('{brand}')
          .join(siteConfig.brand ? siteConfig.brand : '')
      : null;
  }

  return visible && interpolatedValues ? (
    <>
      {interpolatedValues.text ? (
        <Alert severity="info" className="intro-text dyn-desktop-only">
          {/* {cookie ? (
            <IconButton
              className="close-button"
              onClick={() => {
                dataApi.setCookie(cookie, '1');
                setVisible(false);
              }}>
              <CloseIcon />
            </IconButton>
          ) : null} */}
          <span
            dangerouslySetInnerHTML={{
              __html: interpolatedValues.text
            }}></span>
        </Alert>
      ) : null}
      {interpolatedValues.mobileText ? (
        <Alert severity="info" className="intro-text dyn-mobile-only">
          {cookie ? (
            <IconButton
              className="close-button"
              onClick={() => {
                dataApi.setCookie(cookie, '1');
                setVisible(false);
              }}>
              <CloseIcon />
            </IconButton>
          ) : null}
          <span
            dangerouslySetInnerHTML={{
              __html: interpolatedValues.mobileText
            }}></span>
        </Alert>
      ) : null}
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroText);
