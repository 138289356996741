import React, { useEffect, useState, useRef } from 'react';
import { Alert, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import ThousandKWhTrap from '../LandingSC/ThousandKWhTrap';
import Rollercoaster from '../LandingSC/Rollercoaster';
import DontBeAverage from '../LandingSC/DontBeAverage';

import '../TXLandingPage/index.css';
import './SCLandingInline.css';

function SCLandingInline({ truePrice }) {
  const activeTimeout = useRef();
  const [activeSection, setActiveSection] = useState(null);

  function updateActiveSection(section) {
    if (activeTimeout.current) {
      clearTimeout(activeTimeout.current);
    }

    setActiveSection(null);

    if (section) {
      if (activeSection) {
        activeTimeout.current = setTimeout(() => {
          setActiveSection(section);
        }, 0);
      } else {
        setActiveSection(section);
      }
    }
  }

  return (
    <div className="sc-landing-inline">
      {truePrice ? (
        <Alert severity="info" className="true-price-text">
          In Texas, you have a choice when it comes to your electric provider.
          But shoppers need to be careful, the advertised prices found on most
          comparison sites and on the Electricity Facts Label (EFL) can
          sometimes be misleading because they only show you what you’ll pay at
          exactly those usage points. Use more or less than that exact amount
          and your price can go up, sometimes significantly.
          <br />
          <br />
          To help you better understand each plan's actual pricing we’ve
          converted those advertised prices into what we call a “True Price”.{' '}
          <br />
          <br />
          The True Price column below shows you what the average price will be
          for that plan over a 12-month period after taking seasonal usage
          fluctuations into account. This True Price is a much better indicator
          of what you’ll actually pay over that time than the advertised prices
          are.
        </Alert>
      ) : null}

      <div className="sc-landing-inline-content">
        <h3>
          Knowledge is <strong>Power</strong> - how to <strong>Save $</strong>{' '}
          on your next electric plan
        </h3>
        <ul>
          <li className={activeSection === 'trap' ? 'active' : ''}>
            <Button
              onClick={() =>
                setActiveSection(activeSection === 'trap' ? null : 'trap')
              }>
              Avoid the <strong>1,000 kWh trap</strong> by looking at more than
              one price point
              <KeyboardArrowDownIcon />
            </Button>
          </li>
          <li className={activeSection === 'rollercoaster' ? 'active' : ''}>
            <Button
              onClick={() =>
                setActiveSection(
                  activeSection === 'rollercoaster' ? null : 'rollercoaster'
                )
              }>
              Be sure to account for seasonal usage fluctuations{' '}
              <KeyboardArrowDownIcon />
            </Button>
          </li>
          <li className={activeSection === 'average' ? 'active' : ''}>
            <Button
              onClick={() =>
                setActiveSection(activeSection === 'average' ? null : 'average')
              }>
              Shop using our <strong>True Price</strong> rate instead of the
              lowest advertised price <KeyboardArrowDownIcon />
            </Button>
          </li>
        </ul>

        {activeSection ? (
          <div className={'sc-landing content-container'}>
            <div className={activeSection === 'trap' ? 'active' : ''}>
              <ThousandKWhTrap inline />
            </div>
            <div className={activeSection === 'rollercoaster' ? 'active' : ''}>
              <Rollercoaster />
            </div>
            <div className={activeSection === 'average' ? 'active' : ''}>
              <DontBeAverage inline />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SCLandingInline);
